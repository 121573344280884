<template>
  <div>
  <div class="container">
    <div id="apresentacao mb-5" class="container">
      <b-button
        variant="outline-sucess"
        class="m-1 button-whats justify-end"
        pill
        href="https://api.whatsapp.com/send?phone=55556696960994" 
      >
        <img src="/assets/whats.png"
      /></b-button>
      <b-row>
        <b-col
        class="text-center justify-center mx-auto col col-md-12 mt-5">
          <div lass="col-12 justify-center mx-auto text-center">
            <h2 class=" justify-center mx-auto text-center">
              A OPORTUNIDADE CERTA PARA MORAR BEM EM
              <br />
              <b>SINOP-MT!</b>
            </h2>
          </div>          
        </b-col>
        <b-col
        class="text-center justify-center mx-auto col col-md-12 mt-5">
          <h3 >Casas Prontas para Morar</h3>
          <br>
          <h3> Casas Prontas para <b>FINANCIAR</b></h3>
        </b-col>
        <b-col
          cols="12"
          class="text-center justify-center mx-auto col col-md-12 mt-2"
        >
          <div class="box-carousel">
            <b-carousel controls :interval="3000" v-model="slide">
              <b-carousel-slide
                id="slide2"
                class="mt-5 mb-5 pa-5 container-efect"
              >
                <template #img>
                  <img
                    class="img-apresentacao"
                    src="/assets/apresentacao-imovel-moria.webp"
                    alt="image slot"
                  />
                  <b-card-text class=" text-center justify-center mx-auto card-text">
                  <a href="#santacatarina"><h6>Casa Residêncial Santa Catarina</h6></a>
                  </b-card-text>

                  <div class="middle">
                    <div class="text">
                      <b-button  href="#santacatarina" variant="outline-success">
                        Casa Residêncial Santa Catarina
                      </b-button>
                    </div>
                  </div>
                </template>
              </b-carousel-slide>
              <b-carousel-slide id="slide3" class="mt-5 mb-5 container-efect">
                <template #img>
                  <img
                    class="img-apresentacao"
                    src="/assets/MoriaFachada1.jpeg"
                    alt="image Fachada Casa Moriá"
                  />
                <b-card-text class=" text-center justify-center mx-auto card-text">
                  <h6> <a href="#casamoria">Casa Residêncial Moriá</a></h6>  
                </b-card-text>
                  <div class="middle">
                    <div class="text">
                      <b-button variant="outline-success">
                        <a href="#casamoria"></a>
                        Casa Residêncial Moriá
                      </b-button>
                    </div>
                  </div>
                </template>

              </b-carousel-slide>

            </b-carousel>
          </div>
        </b-col>
      </b-row>
    </div>
    <hr>
      <h2> Casa <b>PRONTA</b> para <b>FINANCIAR</b> </h2>
        <h2>Casa Residencial Moriá</h2>
        <hr>

    <div id="casamoria" class="row align-items-center justify-content-between">
        <div class="col-12 col-md-5 p-0 p-md-2">
          <div class="d-flex flex-column">
            <div class="col-12 px-md-2 d-md-block">
              <div class="" style="cursor: pointer">
                <b-img :src="mainImage1" alt="" style="width: 100%" class="image" @click="showMainImage1()"></b-img>
              </div>
            </div>
            <div class="col-12 d-md-block my-2">
              <div class="row">
                <div class="col-3 mt-2" v-for="(image, index) in images1" :key="index">
                  <div class="thumbnail" @click="changeMainImage1(image)">
                    <b-img :src="image" style="width: 100%" alt="" class="image" :class="mainImage1 === image ? 'activess' : ''"></b-img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div class="col-12 col-md-5 p-0 p-md-2">
            <div class="mt-3">
              <h5> ESPECIFICAÇÕES:</h5>
              <h6>104m² Casa | Terreno 22,5x15</h6>
              <h6> Laje toda Casa / Porcelanato toda Casa </h6>
              <h6>Esquadrias Alumínio <b>GOLD</b></h6>
              <h6> Valor: R$ 500.000,00 </h6>
              <b-row class="mx-auto justify-content-center mt-2">
                <a href="https://goo.gl/maps/yUSLPGqtTFQ8p4vR9">
                  <img  class="icon" src="/assets/localizacao.jpeg"/>
                </a>

                <h6> Próximo ao Supercenter / Shopping Sinop / UFMT e BR 163.</h6>
              </b-row>
            </div>
            <hr>
            <h5 class="mt-3"> DESCRIÇÃO:</h5>
            <b-row class="mx-auto justify-content-center mt-2">
              <img  class="icon" src="/assets/banheiro.svg"/>
              <h6 class="mt-2 ml-2">1 Banheiro Social</h6>
            </b-row>
            <b-row class="mx-auto justify-content-center mt-2">
              <img  class="icon" src="/assets/bed-solid.svg"/>
              <h6 class="mt-2 ml-2">1 Suíte</h6>
            </b-row>
            <b-row class="mx-auto justify-content-center mt-2">
              <img  class="icon" src="/assets/bed-solid.svg"/>
              <h6 class="mt-2 ml-2">2 Quartos</h6>
            </b-row>
            <b-row class="mx-auto justify-content-center mt-2">
              <img  class="icon" src="/assets/sala.png"/>
              <h6 class="mt-2 ml-2">Sala</h6>
            </b-row>
            <b-row class="mx-auto justify-content-center mt-2">
              <img  class="icon" src="/assets/lavanderia.png"/>
              <h6 class="mt-2 ml-2">Lavanderia</h6>
            </b-row>
            <b-row class="mx-auto justify-content-center mt-2">
              <img  class="icon" src="/assets/cozinha.png"/>
              <h6 class="mt-2 ml-2">Cozinha</h6>
            </b-row>
             <b-row class="mx-auto justify-content-center mt-2">
              <img  class="icon" src="/assets/garagem.png"/>
              <h6 class="mt-2 ml-2">Garagem para até 2 carros</h6>
            </b-row>
            <b-button variant="outline-dark mt-3 mb-3" href="https://api.whatsapp.com/send?phone=55556696960994">
              Agende agora sua visita!
            </b-button>

       </div>
    </div>
    <hr>
    <h2> Casa <b>PRONTA</b> para <b>FINANCIAR</b> </h2>
    <h2>Casa Residêncial Santa Catarina</h2>
    <hr>

    <div id="santacatarina" class="row align-items-center justify-content-between mt-5 flex-md-row-reverse"> 
        <div class="col-12 col-md-5 p-0 p-md-2">
          <div class="d-flex flex-column">
            <div class="col-12 px-md-2 d-md-block">
              <div class="" style="cursor: pointer">
                <b-img :src="mainImage2" alt="" style="width: 100%" class="image" @click="showMainImage2()"></b-img>
              </div>
            </div>
            <div class="col-12 d-md-block my-2">
              <div class="row">
                <div class="col-3 mt-2" v-for="(image, index) in images2" :key="index">
                  <div class="thumbnail" @click="changeMainImage2(image)">
                    <b-img :src="image" style="width: 100%" alt="" class="image" :class="mainImage2 === image ? 'activess' : ''"></b-img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-5 p-0 p-md-2">
            <div class="mt-3">
              <h5> ESPECIFICAÇÕES:</h5>
              <h6>82m² Casa | Terreno 27,5 x 11</h6>
              <h6>Esquadrias Alumínio SUPREMA</h6>
              <h6> Laje toda Casa / Porcelanato toda Casa </h6>
              <h6> Valor: R$ 340.000,00</h6>
              <b-row class="mx-auto justify-content-center mt-2">
                <a href="https://goo.gl/maps/h7wcyt3CRUXzNV5W7"> <img  class="icon" src="/assets/localizacao.jpeg"/></a>

                <h6> Próximo Supercenter / Posto Petrobrás e BR 163</h6>
              </b-row>
            </div>
            <hr>
            <h5 class="mt-3"> DESCRIÇÃO:</h5>
            <b-row class="mx-auto justify-content-center mt-2">
              <img  class="icon" src="/assets/banheiro.svg"/>
              <h6 class="mt-2 ml-2">1 Banheiro Social</h6>
            </b-row>
            <b-row class="mx-auto justify-content-center mt-2">
              <img  class="icon" src="/assets/bed-solid.svg"/>
              <h6 class="mt-2 ml-2">1 Suíte</h6>
            </b-row>
            <b-row class="mx-auto justify-content-center mt-2">
              <img  class="icon" src="/assets/bed-solid.svg"/>
              <h6 class="mt-2 ml-2">2 Quartos</h6>
            </b-row>
            <b-row class="mx-auto justify-content-center mt-2">
              <img  class="icon" src="/assets/sala.png"/>
              <h6 class="mt-2 ml-2">Sala</h6>
            </b-row>
            <b-row class="mx-auto justify-content-center mt-2">
              <img  class="icon" src="/assets/lavanderia.png"/>
              <h6 class="mt-2 ml-2">Lavanderia</h6>
            </b-row>
            <b-row class="mx-auto justify-content-center mt-2">
              <img  class="icon" src="/assets/cozinha.png"/>
              <h6 class="mt-2 ml-2">Cozinha</h6>
            </b-row>
            <b-button variant="outline-dark mt-3 mb-3" href="https://api.whatsapp.com/send?phone=55556696960994">
              Agende agora sua visita!
            </b-button>

       </div>
    </div>
  </div>
      <Footers class="mt-5"/>
  </div>
</template>

<script>
import Footers from './Footers.vue'
export default {
  name: 'HomesPage',
  props: {

  },
  components: {
    Footers,
  },
  data() {
    return {
      mainImage1: '/assets/MoriaFachada1.jpeg',
      mainImage2: '/assets/scFachada.jpeg',
      images1: [
        '/assets/MoriaFachada1.jpeg',
        '/assets/MoriaFachada2.jpeg',
        '/assets/MoriaFachada3.jpeg',
        '/assets/MoriaFachada4.jpeg',
        '/assets/MoriaSuite.jpeg',
        '/assets/MoriaBanheiroSuite.jpeg',
        '/assets/MoriaQuarto2.jpeg',
        '/assets/MoriaBanheiroSocial.jpeg',
        '/assets/MoriaCozinha.jpeg',
        '/assets/MoriaSalaDeEstar.jpeg',
        '/assets/MoriaExternoFrente.jpeg',
        '/assets/MoriaGramaFundo.jpeg',
      ],
      images2: [
        '/assets/scFachada.jpeg',
        '/assets/apresentacao-imovel-moria.webp',
        '/assets/ScSuite.jpeg',
        '/assets/ScSalaEstar.jpeg',
        '/assets/scQuarto.jpeg',
        '/assets/scCozinha.jpeg',
        '/assets/ScLavanderia.jpeg',
        '/assets/ScBanheiroSocial.jpeg',
        '/assets/ScQuarto2.jpeg',
        '/assets/ScFrenteGrama.jpeg',
        '/assets/ScFundos.jpeg',
        '/assets/ScCorredor.jpeg',

      ],
      slide: 0,
    }
  },
  methods: {
    showMainImage1() {
      this.showImageModal = true;
    },
    showMainImage2() {
      this.showImageModa2 = true;
    },
    changeMainImage1(image) {
      this.mainImage1 = image;
    },
    changeMainImage2(image) {
      this.mainImage2 = image;
    },
  }
}
</script>
<style scoped>
.image {
  max-height: 450px !important;
  border-radius: 10px;
}

.thumbnail img{
  cursor: pointer;
  max-height: 55px !important;
  opacity: 1;
  transition: .3s;
}

.thumbnail:hover img{
  /* background-color: #d2d2d2 !important;  */
  opacity: 0.3 !important;
}

.activess {
  opacity: 0.4 !important;
  border: 3px solid hsl(128, 79%, 46%) !important;
}

i {
  transition: 0.3s;
}

i:hover {
  color: hsl(24, 91%, 86%);
}

</style>